// 请求订单详情
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
// 获取是否需要审批
import consumer_payment_getPayChannelV2 from '@/lib/data-service/haolv-default/consumer_payment_getPayChannelV2'
// 获取是否需要审批
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
// 取消订单
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel' // 取消订单
// 保存保险信息
import consumer_insurance_saveChoosePackage from '@/lib/data-service/haolv-default/consumer_insurance_saveChoosePackage'
// 【1281】获取登录用户的出差政策
import consumer_web_policyConfig_userTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_userTravelPolicy'
// 火车票预定页差标提示
import consumer_trains_product_checkTrainsOrderPolicy from '@/lib/data-service/haolv-default/consumer_trains_product_checkTrainsOrderPolicy'
import consumer_t_tp_refund_change_policy_get from '@/lib/data-service/haolv-default/consumert_tp_refund_change_policyget'
import consumer_trains_order_orderBookingPollingV2
  from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_trains_order_orderBookingPollingV2";
import TrainTimetable from 'yinzhilv-js-sdk/frontend/common-project/component/haolv/train-timetable/1.0.0/index.vue'

const routerName = {
  trainHome: 'admin-travel-book-home-train',
  trainConfirmOrder: 'admin-train-confirm-order',
  paymentCenter: 'admin-payment',
  trainReserveResult: 'admin-train-reserve-result',
  trainChangeApplyResult: 'admin-train-change-apply-result',
  personalTrainSubmitOrder: 'admin-personal-train-submit-order',
};

export default {
  data() {
    return {
      trainsControlRules: 0,   //火车管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
      isHaveOverproof: false,  // false 符合差标、true  超标
      packageCodes: [], // 保险信息
      isFreeApprove: 0,//是否免审--0非免审--1免审
      businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
      //  支付时间--定时器
      countdownFun: null,
      //  支付时间
      milliseconds: 0,
      OccupySeat: false,
      orderFreeApprove: false,
      loading: true,
      orderNo: '',
      evectionNo: '',
      // 1.因私  2.因公
      scheduledWay: 1,
      evectionType: 1,
      // 0.不需要轮询  1.需要轮询
      needPolling: null,
      // 进度条定时器
      IntervalBook: null,
      // 当前进度条--进度
      occupyingDialogWidth: 0,
      // 轮询定时器
      id_setInterval: null,
      // 占座全部失败防止多次触发
      setTimeSwitch: true,
      changeNo: '',
      orderDetail: {},
      changeDetail: {},
      oriPassengerList: [],
      trainData: {
        arriveDate: null,
        whatDay: null,
        arriveTime: null,
        fromStationName: null,
        startDate: null,
        startTime: null,
        toStationName: null,
      },
      seatDetail: {
        seatName: '二等座',
        seatPrice: '123',
      },
      userDetailList:[
        {
          userId: '1',
          passengerName: '张三1',
          passengerTypeText: '张三2',
          passengerCardTypeText: '张三3',
          passengerCardNumber: '张三4',
          seatName: '张三5',
          seatPrice: '张三6',
        },
        {
          userId: '2',
          passengerName: '李四1',
          passengerTypeText: '李四2',
          passengerCardTypeText: '李四3',
          passengerCardNumber: '李四4',
          seatName: '李四5',
          seatPrice: '李四6',
        }
      ],
    }
  },
  components: {
    TrainTimetable
  },
  created() {
  },
  mounted() {
    this.orderNo = this.$route.query.orderNo;
    this.evectionType = this.$route.query.evectionType;
    this.evectionNo = this.$route.query.evectionNo;
    this.needPolling = this.$route.query.needPolling;
    this.scheduledWay = this.$route.query.scheduledWay;
    this.packageCodes = this.$route.query.packageCodes ? this.$route.query.packageCodes : [];
    if (localStorage.getItem('isHaveOverproof') == null) {
      localStorage.setItem('isHaveOverproof',this.$route.query.isHaveOverproof);
      this.isHaveOverproof = this.$route.query.isHaveOverproof;
    } else {
      this.isHaveOverproof = localStorage.getItem('isHaveOverproof');
    }
    consumer_web_policyConfig_userTravelPolicy().then(res => {
      this.trainsControlRules = res.datas.trainsControlRules;
    });
    consumer_journey_orderFreeApprove({businessType: 2, orderNo: this.orderNo}).then(res => {
      this.orderFreeApprove = res.datas.orderFreeApprove;
    });
    consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {

      this.trainData = res.datas;
      this.userDetailList = res.datas.passengers;

      const params = {
        fromStationCode: this.trainData.fromStationCode,
        toStationCode: this.trainData.toStationCode,
        trainCode: this.trainData.trainNo,
        trainDate: this.trainData.startDate,
      };
      this.$refs.aTrainTimetable.init(params);

      if (this.trainData.orderStatus == 1) {
        if (this.needPolling == 1) {
          // 需要轮询
          //正在占座弹框--进度条--开始
          this.IntervalBook = setInterval(() => {
            this.occupyingDialogIng(false);
          },500);
          // 轮询
          this.orderBookingPolling();
        }
      } else {
        this.toPaymentCenterPublic();
      }

    })
  },
  activated() {
  },
  deactivated() {
  },
  beforeDestroy(){
    if (this.id_setInterval) {
      clearInterval(this.id_setInterval)
    }
    if (this.IntervalBook) {
      clearInterval(this.IntervalBook)
    }
    if (this.countdownFun) {
      clearInterval(this.countdownFun)
    }
  },
  destroyed() {
  },
  watch: {},
  computed: {
      countdownTimeStr() {
          let milliseconds = this.milliseconds;
          if (milliseconds && milliseconds > 0) {
              // 转换为式分秒
              let h = parseInt(milliseconds / 60 / 60 % 24);
              h = h < 10 ? '0' + h : h;
              let m = parseInt(milliseconds / 60 % 60);
              m = m < 10 ? '0' + m : m;
              let s = parseInt(milliseconds % 60);
              s = s < 10 ? '0' + s : s;
              // 作为返回值返回
              return `${h}:${m}:${s}`
          } else {
              return '00:00:00'
          }
      }
  },
  filters: {},
  methods: {
    //   取消订单
    toCancelOrder(){
        this.$confirm('一天仅有3次取消订单的机会 (包括自动取消)，超过3次后当日将无法使用12306账号预订。', '确认要取消订单吗？', {
            confirmButtonText: '取消订单',
            cancelButtonText: '稍后再说',
            type: 'warning'
        }).then(() => {
            const loading = this.$loading({
                lock: true,
                text: '',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            consumer_trains_order_applyForCancel({orderNo: this.orderNo}).then(res => {
                loading.close();
                this.$alert('你已成功取消预订', '取消预订提示', {
                    confirmButtonText: '知道了',
                    callback: action => {
                        this.$router.push({
                            name: 'admin-travel-book-home-train'
                        })
                    }
                })
            }).catch(() => {
                loading.close();
                this.$message.error('订单取消失败');
                this.$router.push({
                    name: 'admin-travel-book-home-train'
                })
            })
        }).catch(() => {

        });


    },
    //  去支付
    toCreateOrder(){
        const params = {
            orderNos: this.orderNo,
            evectionNo: this.evectionNo,
            flightOrderList: '',
        };
        this.$router.push({
            name: 'admin-pay',
            query: params
        });
    },
    //轮询占座结果
    orderBookingPolling() {
      let _this = this;
      _this.id_setInterval = setInterval(async function () {
        let res = null;
        res = await consumer_trains_order_orderBookingPollingV2({
          orderNo: _this.orderNo
        });
        const {
          status,
          statusText,
          havePriceChange,
          passengerTakeTicketResult
        } = res.datas;
        _this.occupiedStatus = status;
        _this.occupiedStatusText = statusText;
        // 占座完成
        if (status != 1) {
          clearInterval(_this.IntervalBook);
          clearInterval(_this.id_setInterval);
          _this.occupyingDialogIng(true)
        }

        if (_this.scheduledWay == 1) {
          //占座全部成功，3秒后：0.无变价-跳转至预定中心；1.有变价-跳转至订单信息页
          if (_this.occupiedStatus === 3) {
            consumer_insurance_saveChoosePackage({
              businessType: 2,
              orderNo: _this.orderNo,
              productCodes: _this.packageCodes,
              evectionType: _this.evectionType,
            });
            if (havePriceChange) {
              _this.$message({
                type: "success",
                message: '订单创建成功，实际支付价格以当前实时价格为准！'
              })
            }
            setTimeout(() => {
              _this.toPaymentCenterPrivate();
            })
          }
          //占座全部成功--end

          //占座全部失败，3秒后跳转至火车票行程预定首页
          if (_this.occupiedStatus === 4) {
            _this.$message({
              type: "error",
              message: '创建订单失败'
            });
            setTimeout(() => {
              _this.toTrainHomePrivate()
            }, 2000)
          }
          //占座全部失败--end
        }
        if (_this.scheduledWay == 2) {
          //占座全部成功，3秒后：0.无变价-跳转至预定中心；1.有变价-跳转至订单信息页
          if (_this.occupiedStatus === 3) {
            let isNeedApproval = false;
            consumer_t_tp_refund_change_policy_get().then(res => {
              isNeedApproval = res.datas.businessRuleControl === 2;
              // 免审--
              // if (res.datas.isFreeApprove == 1) {
              //     const params = {
              //         orderNos: this.orderNo,
              //         evectionNo: this.evectionNo,
              //         flightOrderList: '',
              //     };
              //     this.$router.push({
              //         name: 'admin-pay',
              //         query: params
              //     });
              //     return;
              // }
              this.isFreeApprove = res.datas.isFreeApprove;
              this.businessRuleControl = res.datas.businessRuleControl;
              this.toPaymentCenter();

              // if (isNeedApproval) {
              //   setTimeout(() => {
              //     _this.toTrainReserveResultPublic();
              //   });
              // } else {
              //   setTimeout(() => {
              //     if (havePriceChange) {
              //       this.$message({
              //         duration: 4000,
              //         message: '订单创建成功，实际支付价格以当前实时价格为准！',
              //         type: 'success'
              //       });
              //       _this.toOrderDetailPublic();
              //     } else {
              //       _this.toPaymentCenterPublic();
              //     }
              //   }, 3000)
              // }
            });
          };
          //占座全部成功--end

          //占座全部失败，3秒后跳转至火车票行程预定首页
          if (_this.occupiedStatus === 4) {
            setTimeout(() => {
              if (this.setTimeSwitch) {
                this.setTimeSwitch = false;
                this.$message.error('创建订单失败');
              }
            }, 1000);
            setTimeout(() => {
              _this.toTrainHomePublic()
            }, 3000)
          }
          //占座全部失败--end
        }



      }.bind(this), 500)
    },

    //跳转至订单信息页--因公
    toOrderDetailPublic () {
      this.$router.replace({
        name: routerName.trainConfirmOrder,
        query: {
          orderNo: this.orderNo,
          evectionNo: this.evectionNo
        }
      });
    },
    // 跳转火车票行程预定首页--因公
    toTrainHomePublic () {
      this.$router.replace({
        name: routerName.trainHome,
      })
    },
    // 跳转等待审批页面--因公
    toTrainReserveResultPublic (){
      if (this.orderFreeApprove) {
        this.$router.replace({
          name: routerName.trainReserveResult,
          query: {
            orderNo: this.orderNo
          }
        })
      } else {
        this.$router.replace({
          name: routerName.paymentCenter,
          query: {
            evectionNo: this.evectionNo
          }
        })
      }
    },
    toPaymentCenter() {
      if (this.isFreeApprove == 1) {
        let data = {
          channelType: 1,
          orderForPayVos: [
            {
              businessContent: '',
              orderNo: this.orderNo
            }
          ],
          payType: 1,
        };
        consumer_payment_getPayChannelV2(data).then(res => {
          consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
            this.trainData = res.datas;
            this.userDetailList = res.datas.passengers;
          });
          this.OccupySeat = true;
          this.milliseconds =res.datas.milliseconds
          if (this.milliseconds > 0) {
            // 倒计时开始
            this.startCountdown();
          } else if (this.milliseconds === 0) {
            // 弹出提示
            this.showTimeEndDialog();
          } else {
            this.milliseconds = -1;
          }
        });
      }
      if (this.businessRuleControl == 1){
        if (this.isHaveOverproof && this.trainsControlRules != 1) {
          // 超标--提示审批页面
          this.$router.replace({
            name: routerName.trainReserveResult,
            query: {
              orderNo: this.orderNo,
            }
          })
        } else {
          let data = {
            channelType: 1,
            orderForPayVos: [
              {
                businessContent: '',
                orderNo: this.orderNo
              }
            ],
            payType: 1,
          };
          consumer_payment_getPayChannelV2(data).then(res => {
            consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
              this.trainData = res.datas;
              this.userDetailList = res.datas.passengers;
            });
            this.OccupySeat = true;
            this.milliseconds =res.datas.milliseconds
            if (this.milliseconds > 0) {
              // 倒计时开始
              this.startCountdown();
            } else if (this.milliseconds === 0) {
              // 弹出提示
              this.showTimeEndDialog();
            } else {
              this.milliseconds = -1;
            }
          });
        }
      } else {
        if (this.orderFreeApprove || this.isHaveOverproof && this.trainsControlRules != 1) {
          // 超标--提示审批页面
          this.$router.replace({
            name: routerName.trainReserveResult,
            query: {
              orderNo: this.orderNo,
            }
          })
        } else {
          let data = {
            channelType: 1,
            orderForPayVos: [
              {
                businessContent: '',
                orderNo: this.orderNo
              }
            ],
            payType: 1,
          };
          consumer_payment_getPayChannelV2(data).then(res => {
            consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
              this.trainData = res.datas;
              this.userDetailList = res.datas.passengers;
            });
            this.OccupySeat = true;
            this.milliseconds =res.datas.milliseconds
            if (this.milliseconds > 0) {
              // 倒计时开始
              this.startCountdown();
            } else if (this.milliseconds === 0) {
              // 弹出提示
              this.showTimeEndDialog();
            } else {
              this.milliseconds = -1;
            }
          });
        }
      }
    },
    //跳转至预定中心--因公
    toPaymentCenterPublic() {
      if (this.businessRuleControl == 2) {
        this.$router.replace({
          name: routerName.trainReserveResult,
          query: {
            orderNo: this.orderNo,
          }
        })
      } else {
          let data = {
              channelType: 1,
              orderForPayVos: [
                  {
                      businessContent: '',
                      orderNo: this.orderNo
                  }
              ],
              payType: 1,
          };
          consumer_payment_getPayChannelV2(data).then(res => {
              consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo}).then(res => {
                  this.trainData = res.datas;
                  this.userDetailList = res.datas.passengers;
              });
              this.OccupySeat = true;
              this.milliseconds =res.datas.milliseconds
              if (this.milliseconds > 0) {
                  // 倒计时开始
                  this.startCountdown();
              } else if (this.milliseconds === 0) {
                  // 弹出提示
                  this.showTimeEndDialog();
              } else {
                  this.milliseconds = -1;
              }
          });
      }
    },
    startCountdown(){
        if (this.countdownFun) {
            clearInterval(this.countdownFun);
        }
        this.countdownFun = setInterval(()=>{
            this.milliseconds--;
            if (this.milliseconds === 0) {
                clearInterval(this.countdownFun);
                // 弹出提示
                this.showTimeEndDialog();
            }
        }, 1000)
    },
    showTimeEndDialog(){
        this.$alert('支付超时，请重新下单', '支付超时', {
            confirmButtonText: '知道了',
            callback: action => {
                this.$router.push({
                    name: 'admin-travel-book-home-train'
                })
            }
        });

    },
    // 跳转至预定中心--因私
    toPaymentCenterPrivate() {
      this.$router.replace({
        name: routerName.personalTrainSubmitOrder,
        query: {
          orderNo: this.orderNo
        }
      })
    },
    // 返回火车票行程预定首页--因私
    toTrainHomePrivate() {
      this.$router.replace({
        name: routerName.trainHome,
        query: {
          useTravel: 2
        }
      })
    },


    // 进度条运作
    occupyingDialogIng(switchBox) {
      if (switchBox) {
        this.occupyingDialogWidth = 100;
      } else {
        if (this.occupyingDialogWidth <= 89) {
          this.occupyingDialogWidth = this.occupyingDialogWidth + 5;
        } else {

        }
      }
    },
  }
}
